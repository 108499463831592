import axios from "axios";

const RegisterViewer = {

    sendIpAndLocOnly: async (viewer) =>  {
            const api_token = process.env.REACT_APP_TOKEN_TWO
            const url_post_two = process.env.REACT_APP_URL_POST_TWO
            const token = api_token ? api_token : null;
            const headers = {
                Authorization: `Bearer ${token}`
            };
            await axios.post(`${url_post_two}`, {viewer_list: viewer}, {headers})
    },

    viewerRegChecker: async (setViewerEntry) =>{
        const api_token = process.env.REACT_APP_TOKEN_TWO
        const url_get_two = process.env.REACT_APP_URL_GET_TWO
        const token = api_token ? api_token : null;
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const res = await axios.get(`${url_get_two}`, {headers})
        const {data} = res
        if(data){
            setViewerEntry(data)
        }
    }
}

export default RegisterViewer